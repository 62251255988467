import types from 'prop-types';
import { atom, useAtom } from 'jotai';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Colored from 'shared/ui/colored';
import {
    audioControlModel,
    ActivationControl,
    GainControl,
} from 'features/audio-controls';
import * as config from '../config';
import * as lib from '../lib';
import * as model from '../model';
import Timer from './Timer';

/** Enum of component stages. */
const STAGE = {
    READY: 0,
    TRIAL: 1,
    COOLDOWN: 2,
    ACTIVE: 3,
};

/** Atom to manage mode state across components. */
const stageAtom = atom(STAGE.READY);

/** Trial mode timer */
const TrialTimer = () => {
    const [, setStage] = useAtom(stageAtom);
    const deactivate = audioControlModel.useDeactivate(() => setStage(STAGE.COOLDOWN));

    const handleZero = () => {
        deactivate();
    };

    return (
        <Timer
            seconds={config.time.trial}
            onZero={handleZero}
            formatter={lib.format.trialText}
        />
    );
};

/** Cooldown mode timer */
const CooldownTimer = () => {
    const [, setStage] = useAtom(stageAtom);

    const handleZero = () => {
        setStage(STAGE.READY);
    };

    return (
        <Timer
            seconds={config.time.cooldown}
            onZero={handleZero}
            formatter={lib.format.cooldownText}
        />
    );
};

/** Paper surface with rounded top corners and sharp bottom corners. */
const TopSurface = styled(Paper)(({ theme }) => ({
    padding: 4,
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '5px 5px 0px 0px',
}));

/** Paper surface with rounded bottom corners and sharp top corners. */
const BottomSurface = styled(Paper)(({ theme }) => ({
    padding: 4,
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '0px 0px 5px 5px',
}));

interface Props {
    activationColor?: string;
    gainColor?: string;
}

/**
 * Soothing Resonance Control Panel.
 **/
export default function ControlPanel({ activationColor, gainColor }: Props) {
    const [stage, setStage] = useAtom(stageAtom);
    const trialMode = model.useTrialMode();

    let isActivationDisabled;
    let handleActivation;
    let handleDeactivation;
    let statusBarContent;

    if (trialMode) {
        isActivationDisabled = stage === STAGE.COOLDOWN;

        handleActivation = () => {
            setStage(STAGE.TRIAL);
        };

        handleDeactivation = () => {
            setStage(STAGE.COOLDOWN);
        };

        switch (stage) {
            case STAGE.READY:
                statusBarContent = 'Ready!';
                break;
            case STAGE.TRIAL:
                statusBarContent = <TrialTimer />;
                break;
            case STAGE.COOLDOWN:
                statusBarContent = <CooldownTimer />;
                break;
            default:
                statusBarContent = 'Stage Selection Error!';
                break;
        }
    } else {
        isActivationDisabled = false;

        handleActivation = () => {
            setStage(STAGE.ACTIVE);
        };

        handleDeactivation = () => {
            setStage(STAGE.READY);
        };

        switch (stage) {
            case STAGE.READY:
                statusBarContent = 'Ready!';
                break;
            case STAGE.ACTIVE:
                statusBarContent = 'Active!';
                break;
            default:
                statusBarContent = 'Stage Selection Error!';
                break;
        }
    }

    return (
        <>
            <TopSurface>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    width={1}
                    height="48px"
                >
                    <Colored primary={activationColor}>
                        <ActivationControl
                            onActivation={handleActivation}
                            onDeactivation={handleDeactivation}
                            disabled={isActivationDisabled}
                        />
                    </Colored>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyItems="center"
                        width={1}
                        px={2}
                    >
                        <Colored primary={gainColor}>
                            <GainControl />
                        </Colored>
                    </Box>
                </Box>
            </TopSurface>
            <BottomSurface>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={1}
                    height="24px"
                >
                    {statusBarContent}
                </Box>
            </BottomSurface>
        </>
    );
}

ControlPanel.propTypes = {
    trial: types.bool,
};

ControlPanel.defaultProps = {
    trial: false,
};
