import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { Markdown } from 'shared/ui/markdown';
import checkoutSuccessfulMarkdown from './checkout-successful.md';
import checkoutCanceledMarkdown from './checkout-canceled.md';

interface Props {
    onClose: () => void;
    status: 'successful' | 'canceled';
}

export default function Modal({ onClose, status }: Props) {
    const [openState, setOpenState] = useState(true);

    let markdownFile = null;

    switch (status) {
        case 'successful':
            markdownFile = checkoutSuccessfulMarkdown;
            break;
        case 'canceled':
            markdownFile = checkoutCanceledMarkdown;
            break;
        default:
            break;
    }

    const handleClose = () => {
        setOpenState(false);
        !!onClose && onClose();
    };

    return (
        <Dialog open={openState} onClose={handleClose}>
            <DialogContent>
                <Markdown file={markdownFile} />
            </DialogContent>
            <DialogActions>
                <Button color="info" variant="contained" onClick={handleClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Modal.propTypes = {
    /** Is checkout status success? */
    status: PropTypes.string.isRequired,
};
