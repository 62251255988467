// =============================
// Imports
// =============================

// -------------
// MUI v5
import { CssBaseline, ThemeProvider } from '@mui/material';

// -------------
// Router
import { useNavigate, Route, Routes } from 'react-router-dom';

// -------------
// Systems
import { useLoadingSystem } from 'shared/ui/loading';
import { authModel } from 'features/authenticate';
import { CheckoutModal } from 'features/subscribe';
import { useFonts } from 'shared/ui/fonts';
import { theme } from './theme';

// -------------
// Pages
import Layout from './Layout';
import Guide from 'pages/guide';
import NotFound from 'pages/not-found';
import PrivacyPolicy from 'pages/privacy-policy';

// -------------
// Util
import withParams from './hoc/with-router-params';
import PageSkeleton from './PageSkeleton';

// =============================
// Constants
// =============================
const CheckoutModalWithStatus = withParams(CheckoutModal);

const nav = {
    Home: '',
    'Privacy Policy': '/privacy-policy',
};

// =============================
// App Component
// =============================
export default function App() {
    const loader = useLoadingSystem(true);
    const navigate = useNavigate();
    authModel.useSystem();
    useFonts();

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={<Layout nav={nav} />}>
                        <Route path={nav.Home} element={<Guide />} />
                        <Route
                            path={nav['Privacy Policy']}
                            element={<PrivacyPolicy skeleton={<PageSkeleton />} />}
                        />
                        <Route path="checkout">
                            <Route
                                path=":status"
                                element={
                                    <CheckoutModalWithStatus
                                        onClose={() => navigate('/')}
                                    />
                                }
                            />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
                {loader}
            </ThemeProvider>
        </>
    );
}
