import { useEffect } from 'react';
import { User } from 'firebase/auth';
import { atom, useAtom } from 'jotai';
import { userModel } from 'entities/user';
import { firebase } from 'shared/lib';
import { useLoading } from 'shared/ui/loading';

const MODULE = 'features/auth/model';

const { auth } = firebase;

const systemStateAtom = atom<boolean>(false);
const userStateAtom = atom<User | null>(null);

function useSystemState() {
    return useAtom(systemStateAtom);
}

export function useAuthUser() {
    return useAtom(userStateAtom);
}

export function useSystem() {
    const [, setUser] = useAuthUser();
    const [, setInstance] = userModel.useInstance();
    const [isSystemEnabled, setSystemEnabled] = useSystemState();
    const setLoading = useLoading();

    useEffect(() => {
        if (isSystemEnabled) {
            return;
        }

        auth.addUserObserver(MODULE, async (user) => {
            setLoading(true);

            setUser(user);
            if (user !== null) {
                const userEntityInstance = await fetchEntityInstance(user);
                setInstance(userEntityInstance);
            }

            setLoading(false);
        });

        auth.enableSystem();

        setSystemEnabled(true);
    }, [isSystemEnabled, setSystemEnabled, setUser, setInstance, setLoading]);
}

async function fetchEntityInstance(user: User) {
    let instance = await userModel.retrieve(user.uid);
    if (instance === null) {
        instance = await userModel.create(user.uid, { email: user.email || '' });
    }
    return instance;
}
