import { ComponentProps } from 'react';
import { CircularProgress, Backdrop } from '@mui/material';

type Props = ComponentProps<typeof Backdrop>;

export default function Loader({ children, ...props }: Props) {
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
            {...props}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
