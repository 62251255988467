import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const gridColor = '#f8f8f8';

const styles = makeStyles((theme: Theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: `calc(100vh - ${theme.header.height} - ${theme.footer.height} - 1px)`,
        overflow: 'auto',
        backgroundSize: '12px 12px',
        backgroundImage: `linear-gradient(to right, ${gridColor} 4px, transparent 4px), linear-gradient(to bottom, ${gridColor} 4px, transparent 4px)`,
    },
    content: {},
}));

export default styles;
