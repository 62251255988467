import { ComponentProps } from 'react';
import types from 'prop-types';
import { Slider } from '@mui/material';
import * as lib from '../lib';
import * as model from '../model';

type SliderProps = ComponentProps<typeof Slider>;

interface Props extends SliderProps {}

export default function Gain({ disabled }: Props) {
    const [isActivated] = model.useActivationStatus();
    const [gain, setGain] = model.useGain();

    return (
        // @ts-ignore
        <Slider
            color="primary"
            value={gain}
            onChange={(_: any, value) => setGain(value as number)}
            getAriaValueText={lib.slider.getValueText}
            valueLabelDisplay="auto"
            aria-labelledby="volume-slider-label"
            disabled={disabled || !isActivated}
            width={1}
        />
    );
}

Gain.propTypes = {
    /** Track Color. */
    color: types.string,

    /** Is Disabled? */
    disabled: types.bool,
};

Gain.defaultProps = {
    color: 'primary',
    disabled: false,
};
