import { ReactNode } from 'react';
import { Box, ButtonGroup, Typography } from '@mui/material';
import { Scope } from 'jotai/core/atom';
import Bar from './Bar';
import Item from './Item';
import Nav from './Nav';

interface Props {
    title: string | ReactNode;
    nav: Record<string, string>;
    actions?: ReactNode;
    avatar?: ReactNode;
    scope: Scope;
    navTextColor?: string;
    navIndicatorColor?: string;
}

export default function Header({
    title,
    nav,
    actions,
    avatar,
    navTextColor,
    navIndicatorColor,
    scope,
}: Props) {
    return (
        <Bar>
            <Item justifyContent="left">
                <Typography color="#000000" variant="h5" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
            </Item>
            <Item justifyContent="left">
                <Nav
                    pathToLabelMap={nav}
                    textColor={navTextColor}
                    indicatorColor={navIndicatorColor}
                />
            </Item>

            {!!actions && (
                <Item justifyContent="right">
                    <ButtonGroup>{actions}</ButtonGroup>
                    {!!avatar && <Box px={2}>{avatar}</Box>}
                </Item>
            )}
        </Bar>
    );
}
