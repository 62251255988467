import assert from 'assert';
import { JSONSchemaType } from 'ajv';
import { validation } from 'shared/lib';
import schema from './schema.json';
import { UserRecord, Role } from './types';

/**
 *
 *
 * TODO Merge auth system user access with this
 */
export class User {
    data: UserRecord;

    constructor(data: UserRecord) {
        const validate = validation.compile(
            schema as unknown as JSONSchemaType<typeof schema>
        );
        const isDataValid = validate(data);
        const errors = validation.extractErrors(validate);
        assert(
            isDataValid,
            `UserEntity.constructor() failed, schema validation failed, ${errors}`
        );
        this.data = data;
    }

    isSubscribed() {
        return !!this.data.subscription && this.data.subscription.status === 'active';
    }

    isCustomer() {
        return !!this.data.stripe;
    }

    isTester() {
        return this.hasRole('tester');
    }

    private hasRole(role: Role) {
        return this.data.roles !== undefined && this.data.roles.includes(role);
    }
}
