import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Google as GoogleIcon } from 'mdi-material-ui';
import { firebase } from 'shared/lib';
import { useLoading } from 'shared/ui/loading';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ea4335',
        },
    },
});

type Props = React.ComponentProps<typeof Button>;

export default function Google({ children, ...props }: Props) {
    const setLoading = useLoading();

    const handleClick = async () => {
        setLoading(true);
        await firebase.auth.authenticateWithGoogle();
        setLoading(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Button
                {...props}
                color="primary"
                onClick={handleClick}
                startIcon={<GoogleIcon />}
            >
                {children}
            </Button>
        </ThemeProvider>
    );
}

Google.defaultProps = {
    children: 'Google',
};
