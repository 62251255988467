import { atom, useAtom } from 'jotai';
import * as lib from './lib';

/** Get driver for EarPlug web-assembly system.
 *
 * In the event the driver cannot be acquired, a pseudodriver
 * is provided instead.
 * */
let driver: any;
try {
    driver = lib.driver.getDriver();
} catch (error) {
    driver = lib.driver.getPseudoDriver();
}

/** Default EarPlug parameter values between 0 and 100 */
const defaults = {
    suppression: 100,
    gain: 30,
    cutoff: 68,
    delay: 0,
};

/** Value-only state storage.
 *
 * Represents parameter state managed by the app,
 * and parameter value in Earplug by proxy.
 *
 * Values within atoms are updated first, then used
 * to set actual parameters within Earplug.
 *
 * Values are initialized with default values as provided in this module.
 * */
const suppressionAtom = atom(defaults.suppression);
const gainAtom = atom(defaults.gain);
const cutoffAtom = atom(defaults.cutoff);
const delayAtom = atom(defaults.delay);
const activationStatusAtom = atom(false);

/** Acquire value and setter function for Earplug's `suppression` parameter. */
export function useSuppression(): [number, (newValue: number) => void] {
    const [value, setValue] = useAtom(suppressionAtom);
    const setParam = (newValue: number) => {
        try {
            driver.setSuppression(newValue);
            setValue(newValue);
        } catch (error) {}
    };
    return [value, setParam];
}

/** Acquire value and setter function for Earplug's `gain` parameter. */
export function useGain(): [number, (newValue: number) => void] {
    const [value, setValue] = useAtom(gainAtom);
    const setParam = (newValue: number) => {
        try {
            driver.setGain(newValue);
            setValue(newValue);
        } catch (error) {}
    };
    return [value, setParam];
}

/** Acquire value and setter function for Earplug's `cutoff` parameter. */
export function useCutoff(): [number, (newValue: number) => void] {
    const [value, setValue] = useAtom(cutoffAtom);
    const setParam = (newValue: number) => {
        try {
            driver.setCutoff(newValue);
            setValue(newValue);
        } catch (error) {}
    };
    return [value, setParam];
}

/** Acquire value and setter function for Earplug's `delay` parameter. */
export function useDelay(): [number, (newValue: number) => void] {
    const [value, setValue] = useAtom(delayAtom);
    const setParam = (newValue: number) => {
        try {
            driver.setDelay(newValue);
            setValue(newValue);
        } catch (error) {}
    };
    return [value, setParam];
}

/** Acquire value and setter function for the app's believe activation state.
 *
 * May be used to control access to UI components based onactivation state of
 * Earplug.
 * */
export function useActivationStatus() {
    return useAtom(activationStatusAtom);
}

/** Returns function to activate Earplug.
 *
 * Additionally, will (re)-initialize parameter atom values and actual Earplug
 * parameter values. Values are initialized using last known values stored in atoms.
 *
 * @param {function} onSuccess Callback executed on successful activation.
 * @param {function} onError Callback executed on activaiton failure, called with `error` parameter.
 * */
export function useActivate(
    onSuccess?: () => void | Promise<void>,
    onError?: (error: any) => void | Promise<void>
) {
    const [suppression, setSuppression] = useSuppression();
    const [gain, setGain] = useGain();
    const [cutoff, setCutoff] = useCutoff();
    const [delay, setDelay] = useDelay();
    const [, setActivationStatus] = useActivationStatus();

    const initializeParams = () => {
        setSuppression(suppression);
        setGain(gain);
        setCutoff(cutoff);
        setDelay(delay);
    };

    const activate = async () => {
        driver.activate(() => {
            initializeParams();
            setActivationStatus(true);
            !!onSuccess && onSuccess();
        }, onError);
    };

    return activate;
}

/** Returns function to deactivate Earplug.
 *
 * @param {function} onSuccess Callback executed on successful deactivation.
 * @param {function} onError Callback executed on deactivaiton failure, called with `error` parameter.
 * */
export function useDeactivate(
    onSuccess?: () => void | Promise<void>,
    onError?: void | Promise<void>
) {
    const [, setActivationStatus] = useActivationStatus();
    const deactivate = async () => {
        driver.deactivate(() => {
            setActivationStatus(false);
            !!onSuccess && onSuccess();
        }, onError);
    };
    return deactivate;
}
