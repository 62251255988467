import { ComponentProps } from 'react';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

interface Props extends Pick<ComponentProps<typeof ThemeProvider>, 'children'> {
    primary?: string;
    secondary?: string;
}

export default function Colored({ primary, secondary, children }: Props) {
    return (
        <ThemeProvider
            theme={createTheme({
                palette: {
                    primary: !!primary ? { main: primary } : undefined,
                    secondary: !!secondary ? { main: secondary } : undefined,
                },
            })}
            children={children}
        />
    );
}
