import { useState } from 'react';
import {
    Box,
    Grid,
    Stepper,
    Step,
    StepButton,
    Button,
    ButtonGroup,
} from '@mui/material';
import { makeStep } from './lib';
import * as md from './resources';

export const steps = [
    makeStep('Welcome', md.welcome),
    makeStep('Trying it Out!', md.howToUse),
    makeStep('Sign Up', md.signUp),
    makeStep('Subscribe', md.subscribe),
    makeStep('Next Steps', md.nextSteps),
];

const content = Object.fromEntries(steps.map((step, index) => [index, step.content]));

export default function Page() {
    const [activeStep, setActiveStep] = useState<number>(0);

    const changeStep = (step: number) => setActiveStep(step);

    return (
        <Grid container>
            <Grid item xs={3}>
                <Stepper activeStep={activeStep} nonLinear orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepButton
                                color="inherit"
                                onClick={() => changeStep(index)}
                            >
                                {step.label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item xs={9}>
                <ButtonGroup sx={{ margin: 2 }} variant="contained">
                    <Button onClick={() => changeStep(activeStep - 1)}>Previous</Button>
                    <Button onClick={() => changeStep(activeStep + 1)}>Next</Button>
                </ButtonGroup>
                <Box>{content[activeStep]}</Box>
            </Grid>
        </Grid>
    );
}
