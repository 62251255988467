import types from 'prop-types';
import { IconButton } from '@mui/material';
import { PowerSettingsNew as DefaultIcon } from '@mui/icons-material';
import * as model from '../model';
import useStyles from './styles';

type IconProps = React.ComponentProps<typeof DefaultIcon>;
type ColorType = IconProps['color'];

interface Props {
    activeColor: ColorType;
    inactiveColor: ColorType;
    disabled: boolean;
    onActivation: () => void | Promise<void>;
    onDeactivation: () => void | Promise<void>;
    IconComponent: typeof DefaultIcon;
}

export default function Activate({
    activeColor,
    inactiveColor,
    disabled,
    onActivation,
    onDeactivation,
    IconComponent,
}: Props) {
    const classes = useStyles();
    const activate = model.useActivate();
    const deactivate = model.useDeactivate();
    const [isActivated] = model.useActivationStatus();

    const color = isActivated ? activeColor : inactiveColor;

    const handleClick = () => {
        isActivated ? deactivate() : activate();
        isActivated
            ? !!onDeactivation && onDeactivation()
            : !!onActivation && onActivation();
    };

    return (
        <IconButton
            size="large"
            onClick={handleClick}
            disabled={disabled}
            className={classes.button}
            color="info"
        >
            <IconComponent color={color} className={classes.icon} />
        </IconButton>
    );
}

Activate.propTypes = {
    activeColor: types.string,
    inactiveColor: types.string,
    disabled: types.bool,
    onActivation: types.func,
    onDeactivation: types.func,
    IconComponent: types.elementType,
};

Activate.defaultProps = {
    activeColor: 'primary',
    inactiveColor: 'disabled',
    disabled: false,
    onActivation: () => {},
    onDeactivation: () => {},
    IconComponent: DefaultIcon,
};
