import { ComponentProps } from 'react';
import { Button } from '@mui/material';
import * as model from '../model';
import { userModel } from 'entities/user';

interface Props extends ComponentProps<typeof Button> {}

export default function SubscribeButton({ children, ...props }: Props) {
    const [user] = userModel.useInstance();

    const redirectToCheckout = model.useSubscribe(user);

    return (
        <Button {...props} onClick={() => redirectToCheckout()}>
            {children}
        </Button>
    );
}

SubscribeButton.defaultProps = {
    children: 'Subscribe',
};
