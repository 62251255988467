import { ComponentProps } from 'react';
import { Box } from '@mui/material';
import { Markdown } from 'shared/ui/markdown';
import content from '../content.md';

interface Props {
    skeleton?: ComponentProps<typeof Markdown>['skeleton'];
}

export default function Page(props: Props) {
    return (
        <Box width={1} height={1}>
            <Markdown file={content} {...props} />
        </Box>
    );
}
