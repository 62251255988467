import { colors } from '@mui/material';

export const audio = {
    activation: colors.indigo['A100'],
    gain: colors.indigo['A100'],
};

export const nav = {
    text: '#000000',
    indicator: colors.indigo['A100'],
};
