import React from 'react';
import { Button } from '@mui/material';
import { firebase } from 'shared/lib';
import { useLoading } from 'shared/ui/loading';

type Props = React.ComponentProps<typeof Button>;

export default function SignOut({ children, ...props }: Props) {
    const setLoading = useLoading();

    const handleClick = async () => {
        setLoading(true);
        await firebase.auth.signOut();
        setLoading(false);
    };
    return (
        <Button {...props} onClick={handleClick}>
            {children}
        </Button>
    );
}

SignOut.defaultProps = {
    children: 'Sign Out',
};
