import {
    getAuth,
    onAuthStateChanged as firebase_onAuthStateChanged,
    signInWithPopup as firebase_signInWithPopup,
    signOut as firebase_signOut,
    OAuthProvider,
    Unsubscribe,
    User,
} from 'firebase/auth';
import * as config from 'shared/config';
import { app } from './app';

const MODULE = 'shared/api/firebase/auth';

type UserObserverCallback = (user: User | null) => void;

interface UserObserverMap {
    [key: string]: UserObserverCallback;
}

/** Firebase Auth Module */
export const auth = getAuth(app);

export let currentUser: User | null = null;

/**
 * Map of functions (observers) called when auth state changes.
 * Observers are called with auth state as argument.
 * Auth state is either null or is a user object.
 * @private
 **/
const userObservers: UserObserverMap = {};

/**
 * onAuthStateChanged unsubscribe callback.
 * @private
 **/
let offAuthStateChanged: Unsubscribe | null = null;

/**
 * Call auth state observer functions when auth state changes.
 * @private
 * @function
 * */
function callUserObservers(user: User | null): void {
    try {
        for (const key of Object.keys(userObservers)) {
            try {
                userObservers[key](user);
            } catch (error: any) {
                throw new Error(
                    `user observer callback with key "${key}" failed, ${error.message}`
                );
            }
        }
    } catch (error: any) {
        throw new Error(`${MODULE}.callUserObservers() failed, ${error.message}`);
    }
}

/**
 * Register a function to be called whenever the app's auth state changes.
 * @public
 * @function
 * @param {string | Symbol} key Any unique value used to identify observer. Used for removal.
 * @param {function} callback Function called when auth state changes.
 **/
export function addUserObserver(
    key: string,
    callback: UserObserverCallback
): () => void {
    if (key in userObservers) {
        return () => undefined;
    }

    userObservers[key] = callback;

    const remove = () => {
        if (key in Object.keys(userObservers)) {
            delete userObservers[key];
        }
    };

    return remove;
}

/**
 * Remove auth state observer function.
 * Call is ignored if observer is not registered.
 * @public
 * @function
 * @param key Key used to identify observer when registered.
 **/
export function removeUserObserver(key: string): void {
    if (key in Object.keys(userObservers)) {
        delete userObservers[key];
    }
}

/**
 * Enable auth state change events.
 * Registers core auth change event handler with internal observer mechanism.
 * @public
 * @function
 **/
export function enableSystem(): void {
    offAuthStateChanged = firebase_onAuthStateChanged(auth, (user: User | null) => {
        callUserObservers(user);
    });

    addUserObserver(MODULE, (user) => {
        currentUser = user;
    });
}

/**
 * Disable auth state change events.
 * Calls auth system event disconnector and removes all internal observers.
 * @public
 * @function
 **/
export function disableSystem(): void {
    if (!!offAuthStateChanged) {
        offAuthStateChanged();
    }

    callUserObservers(null);

    for (let key in userObservers) {
        delete userObservers[key];
    }

    offAuthStateChanged = null;
}

/**
 * Authenticate with OAuth provider, consuming provider config object.
 *
 * Wraps firebase/auth `signInWithPopup` with error checking, simplified interface, and
 * force refreshes browser window on completion.
 *
 * @private
 * @param {object} providerConfig App specific OAuth provider configuration object.
 **/
async function authenticateWithProvider(
    providerConfig: config.oauth.OAuthProviderConfig
): Promise<void> {
    const { id, scopes } = providerConfig;

    const hasId = !!id;

    try {
        if (!hasId) {
            throw new Error('Missing `id` parameter or is null.');
        }

        const authProvider = new OAuthProvider(id);

        if (scopes) {
            scopes.forEach((scope) => {
                authProvider.addScope(scope);
            });
        }

        await firebase_signInWithPopup(auth, authProvider);
    } catch (error: any) {
        switch (true) {
            case /popup-blocked/.test(error.message):
                return window.alert(`Please allow pop-ups to continue sign-in/sign-up`);
            case /popup-closed-by-user/.test(error.message):
            case /cancelled-popup-request/.test(error.message):
                return;
            default:
                break;
        }

        window.alert(`Authentication Failed: ${error.message}`);
        throw new Error(`Failed to authenticate with OAuth provider, ${error.message}`);
    }
}

export async function authenticateWithGoogle(): Promise<void> {
    await authenticateWithProvider(config.oauth.google);
}

export async function signOut(): Promise<void> {
    await firebase_signOut(auth);
}
