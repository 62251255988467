import React from 'react';
import { Box, AppBar as MuiAppBar, Toolbar } from '@mui/material';

type Props = React.ComponentProps<typeof MuiAppBar>;

export default function Bar({ children, ...props }: Props) {
    return (
        <MuiAppBar
            position="sticky"
            sx={{
                background: 'rgba(255,255,255,0.8)',
                boxShadow: 'none',
                borderBottom: 'solid #e2e2e2 1px',
            }}
            {...props}
        >
            <Toolbar variant="dense">
                <Box
                    alignItems="center"
                    display="flex"
                    height={1}
                    justifyContent="space-between"
                    width={1}
                >
                    {children}
                </Box>
            </Toolbar>
        </MuiAppBar>
    );
}
