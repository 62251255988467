import { firebase } from 'shared/lib';
import { UserRecord } from './types';

const collection = 'users';

export function store(uid: string, data: UserRecord) {
    return firebase.firestore.store<UserRecord>(collection, uid, data);
}

export async function retrieve(uid: string) {
    return firebase.firestore.retrieve<UserRecord>(collection, uid);
}
