import { ComponentProps } from 'react';
import { Button } from '@mui/material';
import { userModel } from 'entities/user';
import * as config from '../config';
import { useLoading } from 'shared/ui/loading';

type Props = ComponentProps<typeof Button>;

export default function Action({ children, ...props }: Props) {
    const [user] = userModel.useInstance();
    const setLoading = useLoading();

    const doesUserExistAndHaveCustomerId = !!user && !!user.data.stripe;

    if (!doesUserExistAndHaveCustomerId) {
        return <></>;
    }

    // @ts-ignore
    const customerId = user.data.stripe.customer.id;

    return (
        <>
            <form id="billingPortalButton" method="POST" action={config.action}>
                <input type="hidden" name="customerId" value={customerId} />
                <input type="hidden" name="returnUrl" value={config.returnUrl} />
            </form>
            <Button
                onClick={() => {
                    setLoading(true);
                    // @ts-ignore
                    document.getElementById('billingPortalButton').submit();
                }}
                {...props}
            >
                {children}
            </Button>
        </>
    );
}

Action.defaultProps = {
    children: 'Billing',
};
