import Ajv, { JSONSchemaType, Options, ValidateFunction } from 'ajv';
import assert from 'assert';

const MODULE = 'shared/lib/validation';

const options: Options = {
    allErrors: true,
    useDefaults: true,
    removeAdditional: true,
};

const instance = new Ajv(options);

export function compile<T>(schema: JSONSchemaType<T>): ValidateFunction {
    try {
        const validate = instance.compile(schema);
        assert(validate, 'validate function instance is null');
        return validate;
    } catch (error: any) {
        throw new Error(`${MODULE}.compile() failed, ${error.message}`);
    }
}

export function extractErrors(validate: ValidateFunction) {
    const { errors } = validate;
    if (!!errors) {
        const messages = Object.values(errors!).map(
            (error) => `[${error.instancePath}] ${error.message}`
        );

        return messages.join('; ');
    }

    return '';
}
