import { ComponentProps } from 'react';
import { Box, Typography } from '@mui/material';
import { userModel } from 'entities/user';

type Props = ComponentProps<typeof Box>;

export default function Status(props: Props) {
    const [user] = userModel.useInstance();

    let welcomeMessage = 'You are not signed in';
    let statusMessage = '';

    if (user) {
        welcomeMessage = `Signed in as: (${user.data.email})`;

        if (user.isTester()) {
            statusMessage = `You have been granted free unlimited access`;
        } else if (!user.isSubscribed()) {
            statusMessage = !!user.data.subscription
                ? 'Subscription Status: ' + user.data.subscription.status
                : 'Free Account';
        }
    }

    return (
        <Box {...props}>
            <Typography align="center">{welcomeMessage}</Typography>
            <Typography align="center">{statusMessage}</Typography>
        </Box>
    );
}
