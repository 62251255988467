import { atom, useAtom } from 'jotai';
import { userApi } from 'shared/api';

export let instance = null;

const instanceAtom = atom<userApi.User | null>(null);

const instanceExtendedAtom = atom(
    (get) => {
        return get(instanceAtom);
    },
    (_, set, value: userApi.User) => {
        set(instanceAtom, value);
    }
);

export function useInstance() {
    return useAtom(instanceExtendedAtom);
}

export async function create(uid: string, data: userApi.User['data']) {
    await userApi.store(uid, { email: data.email });
    const instance = new userApi.User(data);
    return instance;
}

export async function retrieve(uid: string) {
    let instance = null;
    const data = await userApi.retrieve(uid);
    if (data !== null) {
        instance = new userApi.User(data);
    }
    return instance;
}
