import axios from 'axios';
import * as config from 'shared/config';

const MODULE = 'shared/api/checkout';

interface CreateCheckoutSessionParams {
    successUrl: string;
    cancelUrl: string;
    mode: string;
    lineItems: {
        price: string;
        quantity: number;
    }[];
    email?: string;
    customerId?: string;
}

export async function createCheckoutSession(params: CreateCheckoutSessionParams) {
    try {
        const response = await axios.post(
            `${config.backend.url}/v1/checkout-sessions`,
            params
        );
        if (response.status !== 201) {
            throw new Error(
                `response status "${response.status}" does not match expected value "201"`
            );
        }
        return response.data;
    } catch (error: any) {
        throw new Error(`${MODULE}.createCheckoutSession() failed, ${error.message}`);
    }
}
