import { collection, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app } from './app';

const db = getFirestore(app);

export async function store<T>(
    collectionPath: string,
    key: string,
    data: T
): Promise<void> {
    const collectionInstance = collection(db, collectionPath);

    const docRef = doc(collectionInstance, key);

    await setDoc(docRef, data);
}

export async function retrieve<T>(
    collectionPath: string,
    key: string
): Promise<T | null> {
    const collectionInstance = collection(db, collectionPath);

    const docRef = doc(collectionInstance, key);
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
        return null;
    }

    const data = snapshot.data();
    return data as T;
}
