type SuccessEventHandler = () => void | Promise<void>;

const pseudoDriver = {
    activate: (onSuccess?: SuccessEventHandler, _?: any) => {
        console.log('Earplug PseudoDriver: Activated');
        !!onSuccess && onSuccess();
    },

    deactivate: (onSuccess?: SuccessEventHandler, _?: any) => {
        console.log('Earplug PseudoDriver: Deactivated');
        !!onSuccess && onSuccess();
    },

    setSuppression: (value: number) => {
        console.log(`Earplug PseudoDriver: Suppression (${value})`);
    },

    setGain: (value: number) => {
        console.log(`Earplug PseudoDriver: Gain (${value})`);
    },

    setCutoff: (value: number) => {
        console.log(`Earplug PseudoDriver: Cutoff (${value})`);
    },

    setDelay: (value: number) => {
        console.log(`Earplug PseudoDriver: Delay (${value})`);
    },
};

let driver: any = null;

export function getDriver() {
    if (!!driver) {
        return driver;
    }

    if ('SoothingResonance_WAM' in window) {
        // @ts-ignore
        driver = window.SoothingResonance_WAM;
        return driver;
    }

    throw new Error(
        'SoothingResonance driver is not installed or failed to initialize properly!'
    );
}

export function getPseudoDriver() {
    return pseudoDriver;
}
