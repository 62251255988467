import { Skeleton } from '@mui/material';

export default function PageSkeleton() {
    const props = { width: '100%', height: '64px', variant: 'text' as 'text' };
    return (
        <>
            <Skeleton {...props} />
            <Skeleton {...props} />
            <Skeleton {...props} />
            <Skeleton {...props} />
            <Skeleton {...props} />
        </>
    );
}
