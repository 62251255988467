import { useEffect, useState } from 'react';
import { userModel } from 'entities/user';

export function useTrialMode() {
    const [user] = userModel.useInstance();
    const [trialMode, setTrialMode] = useState<boolean>(true);
    useEffect(() => {
        if (user && (user.isSubscribed() || user.isTester())) {
            setTrialMode(false);
        } else {
            setTrialMode(true);
        }
    }, [user]);
    return trialMode;
}

/**
 * source: https://stackoverflow.com/questions/57137094/implementing-a-countdown-timer-in-react-with-hooks
 **/
export function useTimer(seconds: number, onZero?: () => void) {
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) {
            !!onZero && onZero();
            return;
        }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft, onZero]);

    return timeLeft;
}
