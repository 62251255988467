import types from 'prop-types';
import * as model from '../model';

interface Props {
    seconds: number;
    onZero?: () => void;
    formatter?: (time: number) => string;
}

export default function Timer({ seconds, onZero, formatter }: Props) {
    const timeLeft = model.useTimer(seconds, onZero);

    const text = formatter!(timeLeft);

    return <>{text}</>;
}

Timer.propTypes = {
    seconds: types.number,

    onZero: types.func,

    formatTimeText: types.func,
};

Timer.defaultProps = {
    onZero: () => {},

    formatter: (time: number) => `${time}s`,
};
