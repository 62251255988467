import { useState } from 'react';
import { atom, useAtom } from 'jotai';
import Loader from './ui';

const blockTableAtom = atom<{ [key: number]: boolean }>({});

export function useLoading() {
    const [table, update] = useAtom(blockTableAtom);
    const flags = Object.values(table);
    const [key] = useState(flags.length);

    return (blockState: boolean) =>
        update((prevTable) => ({ ...prevTable, [key]: blockState }));
}

export function useSystem(initialState: boolean) {
    const [table] = useAtom(blockTableAtom);
    const flags = Object.values(table);
    let loading;

    if (flags.length === 0) {
        loading = initialState;
    } else {
        loading = flags.some((state) => !!state);
    }

    return <Loader open={loading} />;
}
