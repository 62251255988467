import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    button: {
        borderRadius: 0,
        width: '48px',
        height: '48px',
        color: 'white',
    },
    icon: {
        transform: 'scale(2.4)',
    },
});

export default useStyles;
