import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';

interface Props {
    file: any;
    skeleton?: ReactNode;
    components?: ComponentProps<typeof ReactMarkdown>['components'];
}

export default function Markdown({ file, skeleton, components }: Props) {
    const [content, setContent] = useState<string>('');
    const ref = useRef(false);
    const mergedComponents = { ...muiComponents, ...(components || {}) };

    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        };
    }, []);

    useEffect(() => {
        fetch(file)
            .then((response) => response.text())
            .then((text) => {
                if (ref.current) {
                    setContent(text);
                }
            });
    }, [file]);

    return !!content ? (
        <ReactMarkdown
            components={mergedComponents}
            rehypePlugins={[rehypeRaw]}
            children={content}
        />
    ) : (
        <>{skeleton}</>
    );
}

const muiComponents = {
    h1({ children, ...props }: any) {
        return (
            <>
                <Typography variant="h2" {...props}>
                    {children}
                </Typography>
                <br />
            </>
        );
    },
    h2({ children, ...props }: any) {
        return (
            <>
                <br />
                <br />
                <Typography variant="h4" {...props}>
                    {children}
                </Typography>
                <br />
            </>
        );
    },
    h3({ children, ...props }: any) {
        return (
            <>
                <br />
                <br />
                <Typography variant="h5" {...props}>
                    {children}
                </Typography>
                <br />
            </>
        );
    },
    h4({ children, ...props }: any) {
        return (
            <Typography variant="h6" {...props}>
                {children}
            </Typography>
        );
    },
    p({ children, ...props }: any) {
        return (
            <>
                <Typography variant="h6" {...props}>
                    {children}
                </Typography>
                <br />
            </>
        );
    },
};
