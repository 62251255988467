import { useEffect } from 'react';
import WebFont from 'webfontloader';

export const fonts = {
    // Google
    fancy: 'Edu VIC WA NT Beginner',
};

const fontConfig = {
    google: {
        families: [fonts.fancy],
    },
};

export function useFonts() {
    return useEffect(() => WebFont.load(fontConfig), []);
}
