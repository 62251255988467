import { Tab, Tabs } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import Colored from 'shared/ui/colored';

interface Props {
    pathToLabelMap: Record<string, string>;
    textColor?: string;
    indicatorColor?: string;
}

// https://mui.com/material-ui/guides/routing/#tabs
function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

function renderTab(tuple: [string, string]) {
    const [label, path] = tuple;
    return <Tab key={label} label={label} value={path} to={path} component={Link} />;
}

export default function Nav({ pathToLabelMap, textColor, indicatorColor }: Props) {
    const routeMatch = useRouteMatch(Object.values(pathToLabelMap));
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Colored primary={textColor} secondary={indicatorColor}>
            <Tabs textColor="primary" indicatorColor="secondary" value={currentTab}>
                {Object.entries(pathToLabelMap).map(renderTab)}
            </Tabs>
        </Colored>
    );
}
