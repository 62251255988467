import { Box, Typography } from '@mui/material';
import { fonts } from 'shared/ui/fonts';

export default function Page() {
    return (
        <Box p={8}>
            <Typography variant="h1" align="center" fontFamily={fonts.fancy}>
                Page Not Found
            </Typography>
        </Box>
    );
}
