import { userApi, checkoutApi } from 'shared/api';
import { stripe } from 'shared/lib';
import * as config from './config';
import { useLoading } from 'shared/ui/loading';

export function useSubscribe(user: userApi.User | null) {
    const setLoading = useLoading();

    if (!user) {
        return () => undefined;
    }

    const customerId = !!user.data.stripe ? user.data.stripe.customer.id : undefined;
    const email = !!customerId ? undefined : user!.data.email;

    return async () => {
        setLoading(true);
        try {
            const checkoutSession = await checkoutApi.createCheckoutSession({
                successUrl: config.successUrl,
                cancelUrl: config.cancelUrl,
                mode: config.mode,
                lineItems: [{ price: config.subscriptionPrice, quantity: 1 }],
                email,
                customerId,
            });

            await stripe.instance.redirectToCheckout({
                sessionId: checkoutSession.id,
            });
        } catch (error: any) {
            setLoading(false);
            throw new Error(
                `useSubscribe() -> redirectToCheckout() failed, ${error.message}`
            );
        }
    };
}
