import { Box, ButtonGroup } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { userModel, UserStatus } from 'entities/user';
import { GoogleAuthButton, SignOutButton } from 'features/authenticate';
import { BillingPortalButton } from 'features/billing-portal';
import { SubscribeButton } from 'features/subscribe';
import { AudioControlPanel } from 'widgets/audio-control-panel';
import Header from 'widgets/header';
import useStyles from './styles';
import scope from './scope';
import * as colors from './colors';

interface Props {
    nav: Record<string, string>;
}

export default function Layout({ nav }: Props) {
    const [user] = userModel.useInstance();
    const classes = useStyles();

    const isCustomer = !!user && user.isCustomer();
    const isSubscribed = !!user && user.isSubscribed();

    const headerProps = {
        actions: !!user ? (
            <>
                <ButtonGroup variant="contained">
                    {!isSubscribed && <SubscribeButton />}
                    {isCustomer && <BillingPortalButton />}
                    <SignOutButton />
                </ButtonGroup>
            </>
        ) : (
            <GoogleAuthButton>Sign In with Google</GoogleAuthButton>
        ),
    };

    return (
        <>
            <Header
                nav={nav}
                title="Soothing Resonance"
                scope={scope}
                navTextColor={colors.nav.text}
                navIndicatorColor={colors.nav.indicator}
                {...headerProps}
            />

            <main className={classes.main}>
                <Box p={6}>
                    <AudioControlPanel
                        activationColor={colors.audio.activation}
                        gainColor={colors.audio.gain}
                    />

                    <br />
                    <br />

                    <UserStatus />

                    <br />

                    <Outlet />
                </Box>
            </main>
        </>
    );
}
